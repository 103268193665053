import { styled, keyframes } from "styled-components";
import { layoutPadding } from "~/styles/mixins";
import { Link } from "@remix-run/react";

export const flickerAnimation = keyframes`
  0% { background-color: black; }
  50% { background-color: transparent; }
  100% { background-color: black; }
`;

export const StyledCartItem = styled.div`
  ${layoutPadding};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  padding-bottom: ${({ theme }) => theme.space[8]};
  padding-top: ${({ theme }) => theme.space[8]};
  transition: background-color 0.1s;
  cursor: pointer;
  ${({ theme }) => theme.breakpoints.up("desktop")} {
    &:hover {
      animation: ${flickerAnimation} 150ms;
      background-color: black;
    }
  }
`;

export const CartProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space[8]};
  grid-column: 1 / -1;
  grid-row: 1;
`;

export const CartProductDescriptionMobile = styled.div`
  display: grid;
  column-gap: ${({ theme }) => theme.space.gridColumnGap};
  grid-template-columns: repeat(6, 1fr);
  grid-column: 1 / -1;
  grid-row: 3;

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

export const CartProductDescription = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    display: grid;
    column-gap: ${({ theme }) => theme.space.gridColumnGap};
    grid-column: 1 / -1;
    grid-row: 3;
    grid-template-columns: repeat(18, 1fr);
  }
`;

export const CardProductImage = styled(Link)`
  margin: 0 auto ${({ theme }) => theme.space[8]};
  grid-column: 4 / -2;
  grid-row: 2;

  ${({ theme }) => theme.breakpoints.up("desktop")} {
    grid-column: 11 / 13;
  }

  img {
    object-fit: cover;
    max-width: 132px;
    aspect-ratio: 3 / 4;
  }
`;
