import styled, { css } from "styled-components";
import { flickerAnimation } from "../CartItem/CartItem.styles";

const baseStyling = () => css`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

type Size = "small" | "medium" | "large";

export interface StyledButtonProps {
  $active?: boolean;
  $inverted?: boolean;
  $stroke?: boolean;
  $size?: Size;
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${baseStyling};
  border: 1px solid transparent;
  display: block;
  font-family: ${({ theme }) => theme.fonts.abcWalterNeue};
  line-height: 1;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-transform: uppercase;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    animation: ${flickerAnimation} 150ms;
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ $inverted, theme }) =>
    $inverted &&
    css`
      background-color: ${theme.colors.white};
      color: ${theme.colors.black};
      &:hover {
        ${baseStyling};
        border-color: ${theme.colors.white};
      }
    `}

  ${({ $size, theme }) =>
    $size === "small" &&
    css`
      font-size: ${theme.fontSizes[10]};
      padding: 7px ${theme.space[12]};
    `}

  ${({ $size, theme }) =>
    $size === "medium" &&
    css`
      font-size: ${theme.fontSizes[14]};
      font-weight: ${theme.fontWeights.medium};
      padding: ${theme.space[12]} ${theme.space[16]};
    `}

  ${({ $size, theme }) =>
    $size === "large" &&
    css`
      font-size: ${theme.fontSizes[14]};
      font-weight: ${theme.fontWeights.medium};
      padding: ${theme.space[16]};
    `}

  ${({ $stroke, theme }) =>
    $stroke &&
    css`
      background-color: transparent;
      border-color: ${theme.colors.black};
      color: ${theme.colors.black};

      &:hover {
        ${baseStyling};
      }
    `}

  ${({ $active }) =>
    $active &&
    css`
      ${baseStyling};
    `}
`;

export const Line = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  height: 0;
  left: -1;
  position: absolute;
  transform-origin: left;
`;
