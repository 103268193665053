import {
  ComponentPropsWithoutRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { Line, StyledButton, StyledButtonProps } from "./Button.styles";
import { useWindowSize } from "react-use";

export interface ButtonProps
  extends Omit<
    ComponentPropsWithoutRef<typeof StyledButton>,
    "$active" | "$inverted" | "$size" | "$stroke"
  > {
  active?: StyledButtonProps["$active"];
  as?: string;
  children: ReactNode;
  inverted?: StyledButtonProps["$inverted"];
  isCrossedThrough?: boolean;
  size?: StyledButtonProps["$size"];
  stroke?: StyledButtonProps["$stroke"];
}

const Button = ({
  active = false,
  children,
  inverted = false,
  isCrossedThrough = false,
  size = "large",
  stroke = false,
  ...props
}: ButtonProps) => {
  const { width: windowWidth } = useWindowSize();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [lineValues, setLineValues] = useState({
    angle: 0,
    hypotenuse: 0,
  });

  useEffect(() => {
    if (buttonRef.current && isCrossedThrough && windowWidth) {
      // Calculations based on https://stackoverflow.com/questions/27784776/how-do-i-calculate-the-angle-of-a-right-triangle-using-the-javascript-math-libra
      const { width, height } = buttonRef.current.getBoundingClientRect();
      const hypotenuse = Math.hypot(width, height);
      const sinOfAngleX = height / hypotenuse;
      const angle = (Math.asin(sinOfAngleX) * 180) / Math.PI;

      setLineValues({
        angle,
        hypotenuse,
      });
    }
  }, [isCrossedThrough, windowWidth]);

  return (
    <StyledButton
      {...props}
      $active={active}
      $inverted={inverted}
      ref={buttonRef}
      $size={size}
      $stroke={stroke}
    >
      {children}
      {isCrossedThrough && (
        <>
          <Line
            style={{
              top: -1,
              transform: `rotate(${lineValues.angle}deg)`,
              width: lineValues.hypotenuse,
            }}
          />
          <Line
            style={{
              bottom: -1,
              transform: `rotate(-${lineValues.angle}deg)`,
              width: lineValues.hypotenuse,
            }}
          />
        </>
      )}
    </StyledButton>
  );
};

export default Button;
